<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.conta', 2) + ' ' + $tc('global.aPagar')"
      icon="mdi-cart-outline"
      dark
    />

    <v-card>
      <v-card-title class="px-md-6 subtitle-1 tocs_gray_1">
        <Filtros P_periodo P_moeda P_status :P_statusArray="filterStatus" />
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          solo
          flat
          outlined
          dense
          hide-details
        ></v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <v-btn
          v-if="PContaPagarAdicionar"
          color="button_1"
          class="white--text"
          depressed
          @click="dialogContaPagarAdd"
        >
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text class="py-6">
        <v-data-table
          :headers="headers"
          :items="contas"
          :search="search.length >= 3 ? search : ''"
          :items-per-page="15"
          class="data-tables"
          :class="{
            'data-tables__row-click': PContaPagarEditar,
          }"
          :loading="loading"
          @click:row="dialogContaPagarEdit"
        >
          <template v-slot:item.fornecedor_nome="{ item }">
              <span>
                {{ item.fornecedor_nome }}
              </span>
              <v-tooltip v-if='item.origem_tabela == "ORDEM_SERVICO"' bottom color="darkblue">
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    icon
                    @click.stop="openVendaInfo(item)"
                    v-on="on"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <span>Informações da venda</span>
              </v-tooltip> 
          </template>

          <template v-slot:item.data_lancamento="{ item }">
            {{ item.data_lancamento | dateFormat("dd/MM/yyyy") }}
          </template>

          <template v-slot:item.data_vencimento="{ item }">
            {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
          </template>

          <template v-slot:item.data_pagamento="{ item }">
            {{ item.data_pagamento | dateFormat("dd/MM/yyyy") }}
          </template>

          <template v-slot:item.desconto="{ item }">
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.moeda_sigla }} {{ item.desconto | guarani }}
            </span>
            <span v-else>
              {{ item.moeda_sigla }} {{ item.desconto | guarani }}
            </span>
          </template>

          <template v-slot:item.valor="{ item }">
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.moeda_sigla }} {{ item.valor | guarani }}
            </span>
            <span v-else>
              {{ item.moeda_sigla }} {{ item.valor | guarani }}
            </span>
          </template>

          <template v-slot:item.valor_pago="{ item }">
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.moeda_sigla }} {{ item.valor_pago | guarani }}
            </span>
            <span v-else>
              {{ item.moeda_sigla }} {{ item.valor_pago | guarani }}
            </span>
          </template>
          <template v-slot:item.saldo="{ item }">
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.moeda_sigla }} {{ item.saldo | guarani }}
            </span>
            <span v-else>
              {{ item.moeda_sigla }} {{ item.saldo | guarani }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <DialogContaPagar
      v-if="dialogContaPagar"
      :dialogContaPagar.sync="dialogContaPagar"
      :edit="edit"
      :conta_id="conta_id"
      @fetch-contas="fetchContasPagar"
    />
    <DialogContaPagarViewr
      v-if="dialogContaPagarViewr"
      :dialogContaPagarViewr.sync="dialogContaPagarViewr"
      :conta_id="conta_id"
      @fetch-contas="fetchContasPagar"
    />
    <DialogVendaInfo
      v-if="dialogVendaInfo"
      :dialogVendaInfo.sync="dialogVendaInfo"
      :venda="venda"
      :venda_parcelas="venda_parcelas"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getContasPagar } from "@/api/contas-pagar/contas_pagar.js";
import { getVendaInfo, getVendaIdByOrdemServicoId } from "@/api/vendas/vendas.js";

export default {
  name: "ContasPagar",

  components: {
    Filtros: () => import("@/components/filtros/Filtros.vue"),
    DialogContaPagar: () => import("./components/DialogContaPagar.vue"),
    DialogContaPagarViewr: () =>
      import("./components/DialogContaPagarViewr.vue"),
    DialogVendaInfo: () => import("@/views/dashboard/caixas-pdv/components/DialogVendaInfo.vue"),
  },

  data() {
    return {
      loading: false,
      search: "",
      edit: false,
      dialogContaPagar: false,
      dialogContaPagarViewr: false,
      dialogVendaInfo: false,
      contas: [],
      conta_id: null,
      venda: null,
      venda_parcelas: [],
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PContaPagarAdicionar() {
      return this.getAccess("ContasPagar", "adicionar");
    },
    PContaPagarEditar() {
      return this.getAccess("ContasPagar", "editar");
    },
    headers() {
      return [
        {
          text: this.$tc("global.fornecedor"),
          value: "fornecedor_nome",
        },
        {
          text: this.$tc("global.documento"),
          value: "documento",
        },
        {
          text: this.$tc("global.origem"),
          value: "origem_tabela",
        },
        {
          text: this.$tc("global.data-vencimento"),
          align: "center",
          value: "data_vencimento",
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
        },
        {
          text: this.$tc("global.saldo"),
          value: "saldo",
        },
        {
          text: this.$tc("global.desconto"),
          value: "desconto",
        },
        {
          text: this.$tc("global.valor") + " " + this.$tc("global.pago"),

          value: "valor_pago",
        },
        {
          text: this.$tc("global.saldo"),
          value: "saldo",
        },

        // {
        //   text: this.$tc("global.valor"),
        //   value: "valor",
        // },
      ];
    },

    filterStatus() {
      return [
        {
          text: this.$tc("global.contaPaga", 2),
          value: "pagas",
        },
        {
          text: this.$tc("global.aberto"),
          value: "aberto",
        },
        {
          text: this.$tc("global.vencido", 2),
          value: "vencidas",
        },
      ];
    },

    url() {
      let queryString = "";
      for (let key in this.$route.query) {
        queryString += `&${key}=${this.$route.query[key]}`;
      }
      return `?${queryString}`;
    },
  },

  watch: {
    url() {
      this.fetchContasPagar();
    },
  },

  methods: {
    dialogContaPagarAdd() {
      this.dialogContaPagar = true;
      this.edit = false;
      this.conta_id = null;
    },

    async openVendaInfo(item) {
      let ordem_servico_id = item.origem_id;
      let venda_id = await getVendaIdByOrdemServicoId(ordem_servico_id);
      this.venda = await getVendaInfo(venda_id);
      if (this.venda.parcelas) {
        this.venda_parcelas = this.venda.parcelas;
      }
      this.dialogVendaInfo = true;
    },

    dialogContaPagarEdit(item) {
      this.conta_id = item.id;
      if (item.origem_tabela === "MANUAL") {
        this.dialogContaPagar = true;
        this.edit = true;
      } else if (item.origem_tabela !== "MANUAL") {
        this.dialogContaPagarViewr = true;
        this.edit = false;
      }
    },

    fetchContasPagar() {
      this.loading = true;
      return getContasPagar(this.url)
        .then((response) => {
          this.contas = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.fetchContasPagar();
  },
};
</script>

<style></style>
